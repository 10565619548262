import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Form from "../components/stay-connected-form";
import Modal from 'react-modal';
import closeIcon from '../_images/icons/white/close.svg';
import ReactGA from 'react-ga';
import DOD from '../components/dods';
import { withPrefix } from 'gatsby'
import Vimeo from '@u-wave/react-vimeo';

import Frame1 from '../_images/profiles/bryan-ruef-2.jpg';
import Frame2 from '../_images/profiles/bryan-ruef-3.jpg';
import socialBanner from '../_images/profiles/bryan-ruef-social.jpg';

Modal.setAppElement('#___gatsby')

export default class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      videoModalIsOpen: false,
      width: 0,
      height: 0
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openVideoModal = this.openVideoModal.bind(this);
    this.closeVideoModal = this.closeVideoModal.bind(this);
    this.logger = this.logger.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.videoTeaser = React.createRef();
  }

  openModal() {
    this.setState({modalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Receive Updates'
    });
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  openVideoModal() {
    this.setState({videoModalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Watch Video'
    });
  }

  closeVideoModal() {
    this.setState({videoModalIsOpen: false});
  }

  playVideo = () => {
    this.videoTeaser.current.play();
  }

  pauseVideo = () => {
    this.videoTeaser.current.pause();
  }

  logger(e) {
    // ga('send', 'event', 'Button', 'Give Now', 'Campaign Profile');
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: e.currentTarget.textContent
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  
  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    var pageTitle = 'Titan of Rescue';
    var pageDesc = 'Working as an emergency medical technician and sheriff’s department volunteer, computer science major Bryan Ruef was astonished by the outdated, error-prone systems cities relied on during emergencies';

    // console.log('width: ' + this.state.width );
    var windowHeight = {
      minHeight: this.state.height
    }
    return (
      <React.Fragment>
      <Layout locationInfo={this.props.location}>
        <Helmet>
          <title>{pageTitle}</title>
          <body className="profile-landing-page" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />

          <meta property="og:video" content={ this.props.location.origin + withPrefix('/_video/teaser-reuf.mp4') } />
          <meta property="og:video:secure_url" content={ this.props.location.origin + withPrefix('/_video/teaser-reuf.mp4') } />
          <meta property="og:video:type" content="video/mp4" />
          <meta property="og:video:width" content="1920" />
          <meta property="og:video:height" content="1080" />
          <meta property="og:image" content={ this.props.location.origin + withPrefix('/_video/teaser-reuf.jpg') } />

          <link href="https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap" rel="stylesheet"></link>
        </Helmet>
        <section className="landing-wrap bryan-ruef" style={windowHeight}>
          <div className="profile-text" style={windowHeight}>
            <div className="text-wrap">
              
              <div className="row">
                <h1>Titan of Rescue</h1>
                <div className="col-3">  
                  <blockquote>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>“</title><path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"/></svg>
                    <p>“The Center for Entrepreneurship has made a tremendous difference in making me into a businessman – teaching me business basics and how to pitch our company. The CSUF Startup Incubator has given us access to amazing resources. It’s been great to run our ideas by seasoned entrepreneurs.”</p>
                  </blockquote>
                  <div className="clear"></div>
                  
                </div>
                <div className="col-9">
                  <p>Working as an emergency medical technician and sheriff’s department volunteer, computer science major Bryan Ruef was astonished by the outdated, error-prone systems cities relied on during emergencies.</p>
                  <p>He developed an affordable, cloud-based dispatching software product but wasn’t sure how to bring it to market.</p>
                  <p>The entrepreneur-in-residence program at the College of Engineering and Computer Science — a collaboration between CSUF’s <a href="https://business.fullerton.edu/center/entrepreneurship" target="_blank">Center for Entrepreneurship</a> and ECS &mdash; encouraged him to compete in the CSUF Startup Competition for a chance to win customized launch support in the CSUF Startup Incubator.</p>
                  <p>Bryan won the competition and now has a team of advisers helping him grow his company, <em>10-8 Systems</em>. His software is used in 48 states for disaster response; it saved more than 650 lives in Hurricane Dorian.</p>
                  <p>Bryan credits CSUF’s entrepreneurship resources for putting him on the path to success — making his dream of improving public safety a reality.</p>
                  <h2>Be the difference in a Titan's life.</h2>
                  <p><strong>Support entrepreneurship in ECS today.</strong></p>
                  <ul className="actions">
                    <li><a href="https://give.fullerton.edu/RescueCampaign" className="buttonBlue" onClick={this.logger}>Donate Now</a></li>
                    {/* <li><a href="https://business.fullerton.edu/center/entrepreneurship" className="buttonBlue outline" onClick={this.logger}>Learn More</a></li> */}
                    <li><button className="buttonBlue outline" onClick={this.openModal}>Receive Updates</button></li>
                  </ul>

                  <DOD college="ecs" layout="2" />

                </div>
              </div>
              
            </div>

            

          </div>
          <img src={Frame1} className="frame animated fadeIn" id="frame1" alt="Bryan Ruef demonstrates his software to first responders." />
          <img src={Frame2} className="frame animated fadeIn" id="frame2" alt="A close up of 10-8 Systems software." />
          <div className="video-wrap animated fadeIn" onMouseEnter={this.playVideo} onMouseLeave={this.pauseVideo}>

            <button onClick={this.openVideoModal} id="playVideo">
              <div></div>
              <div></div>
              <div></div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><title>Watch Video</title><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"/></svg>
            </button>

            <video ref={this.videoTeaser} id="heroVideo" loop muted="muted" poster={ withPrefix('/_video/teaser-reuf.jpg') } title="Intro Video">
              <source src={ withPrefix('/_video/teaser-reuf.webm') } type="video/webm; codecs=vp9,vorbis" />
              <source src={ withPrefix('/_video/teaser-reuf.mp4') } type="video/mp4" />
              <track kind="descriptions"  src={ withPrefix('/_video/teaser-reuf.html') } />
            </video>

          </div>
        </section>

        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Receive Updates"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <div className="wrap">
            <Form profile="resuce" college="the College of Engineering and Computer Science" />
          </div>
        </Modal>
        
        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.videoModalIsOpen}
          onRequestClose={this.closeVideoModal}
          contentLabel="Watch Video"
          className="videoModal"
          overlayClassName="videoOverlay"
          shouldCloseOnOverlayClick={true}
        >
          <button onClick={this.closeVideoModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <div className="wrap-video">
            <Vimeo
              video="393814669"
              responsive
              showTitle="false"
              showByline="false"
              showPortrait="false"
              autoplay
              onEnd={this.closeVideoModal}
            />
          </div>
        </Modal>
        
      </Layout>
      </React.Fragment>
    );
  }
}
